import { RouterState, RouterStore } from "mobx-state-router";
import { BatchList } from "../../models/batch/BatchList";
import BatchFormState from "../../components/codes/batches/BatchFormState";
import BatchListState from "../../components/codes/batches/BatchListState";
import { buildColumns as buildBatchColumns } from "../../pages/codes/batches/List";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import async from "../../components/Async";
import React from "react";
import {BatchFilterSelectState} from "../../components/elements/filters/BatchFilterSelectState";
import BatchItemListState from "src/components/codes/batches/items/BatchItemListState";
import {BatchItemList} from "../../models/batch/BatchItemList";
import { buildColumns as buildBatchItemsColumns } from "../../pages/codes/batches/items/BatchItemsList";
import { CodeModelList } from "../../models/codes/Code"; 
import { QrDownloadState } from "../../components/qr/qrpreview/qrDownload/QrDownloadState";

const BatchesPage = async(() => import("../../pages/codes/batches/List"));
const BatchItemsPage = async(() => import("../../pages/codes/batches/items/BatchItemsList"));

export const viewMap = {
  batches: <BatchesPage/>,
  batchItems: <BatchItemsPage/>
}

export const routeDefinitions = [
  {
    path: "batches",
    name: "Batches",
    icon: faMailBulk,
    component: BatchesPage,
    children: null,
    featureFlagRequired: "cmp_ui_batches"
  }
]

export const routeTransitions = [
  {
    name: 'batches',
    pattern: '/batches',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const batchFilterSelectState = rootStore.rootUiStore.batchFilterSelectState
      const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState
      const publishDomainSelectStore = rootStore.rootUiStore.publishDomainSelectStore
      const codeFilterSelectState = rootStore.rootUiStore.codeFilterSelectState;
      const qrConfigSelectStore = rootStore.rootUiStore.qrConfigSelectStore
      const batchSelectStore = rootStore.rootUiStore.batchSelectStore;
      const batchList = rootStore.rootUiStore.batchList;
      codeFilterSelectState.reset();
      tagSelectStore?.tagStore?.init(); //reset tags
      tagSelectStore?.setSelectedTags([]);
      dimensionSelectViewState?.clearSelections()
      dimensionSelectViewState?.setDefaults()
      batchFilterSelectState.reset()
      groupSelectState.selectGroup(groupSelectState.groupStore?.groups[0]);
      publishDomainSelectStore.refresh(groupSelectState.groupStore?.groups[0]);
      const codes = CodeModelList.create({});

      // const initBatchStore = BatchStore.create({storeUuid: 'btts'});
      // initBatchStore.getBatches();
      // const batchSelectStore = BatchSelectState.create({selectStateUuid: 'bss',})
      // batchSelectStore.setBatches(initBatchStore);

      //batchSelectStore.batchStore.getBatches();
      
      // const batchList = BatchList.create({selectStateUuid: 'bss'});
      
      const batchFormState = BatchFormState.create({
        title: "New Batch",
        tagSelectStore: tagSelectStore,
        groupSelectStore: groupSelectState,
        dimensionSelectViewState: dimensionSelectViewState,
        publishDomainSelectStore: publishDomainSelectStore,
        codeFilterSelectState: codeFilterSelectState,
        qrConfigSelectStore: qrConfigSelectStore,
        codeModels: codes,
        batchSelectStore: batchList,
        qrDownloadStore: QrDownloadState.create({})
      })
      
      const viewState = BatchListState.create({
        store: batchList,
        columns: buildBatchColumns(routerStore, batchList),
        groupSelectStore: groupSelectState,
        batchFormState: batchFormState,
        batchFilterSelectState: batchFilterSelectState,
        dimensionSelectViewState: dimensionSelectViewState,
      });
      
      console.log("Attaching Page State : batchListViewState ")
      await rootStore.rootUiStore.attachPageState('batchListViewState', viewState)
      // load codes filter list
      await batchFormState.fetchFilters();
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      //setTimeout(()=>{rootStore.rootUiStore.detachPageState('batchListViewState')},100)

    }
  },
  {
    name: 'batchItems',
    pattern: '/batches/:batchUuid/items',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      const batchItems = BatchItemList.create({
        batchUuid: toState.params.batchUuid
      })
      const batchItemListState = BatchItemListState.create({
        store: batchItems,
        columns: buildBatchItemsColumns(routerStore)
      })
      console.log("Attaching Page State : batchItemListViewState ")
      await rootStore.rootUiStore.attachPageState('batchItemListViewState', batchItemListState)

    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;


    }
  }
];
