import { flow, getEnv, types } from "mobx-state-tree";
import BatchStore from "../../stores/domain/BatchStore";
import { Batch } from "./Batch";

export const BatchList = types.model('BatchList',{
  selectStateUuid: types.maybe(types.identifier),
  batchStore: types.maybe(types.late(() => types.reference(BatchStore))),
  isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
  page: types.optional(types.integer, 1),
  perPage: types.optional(types.integer, 10),
  totalRows: types.optional(types.integer, 0),
  sortOrder: types.optional(types.string, "desc"),
  sortField:  types.optional(types.string, "updatedAt"),
  selectedBatch: types.maybe(types.string),
}).volatile(self => ({
  items: [],
  searchParams: {}
}))
  .actions((self) => ({
    getItems() {
      return self.batchStore.items
    },
    setSortOrder(sortDirection){
      self.sortOrder = sortDirection
    },
    setSortField(sortField){
      self.sortField = sortField
    },
    fetchAll: flow(function* fetchAll(sortParams = {}) {
      // self.items = []
      self.isLoading = true
      if (Object.keys(sortParams).length === 0) {
        sortParams = {
          sort: 'updatedAt',
          order: self.sortOrder
        };
      }
      try {
        const queryParams = {
          page: self.page,
          perPage: self.perPage,
          ...sortParams,
          ...self.searchParams
        }
        // ... yield can be used in async/await style
        const manager = getEnv(self).batchManager
        const response = yield manager.fetchBatches(queryParams)
        //CodeModel.create({uuid: one.uuid, status: one.status}) //TODO: convert
        console.log('Batches List:',response)
        if(response){
          // self.items = response.results;
          self.totalRows = response._meta.itemCount;
          self.batchStore.setItems(response.results);
        }

        self.isLoading = false;
      } catch (error) {
        console.error('Failed to fetch tags', error)
      }
    }),
    updatePage: function (page) {
      self.page = page
    },
    updatePerPage: function (perPage) {
      self.perPage = perPage
    },
    addSearchParams(param){
      self.searchParams = {...self.searchParams, ...param}
    },
    removeSearchParams(names){
      if(names){
        names.map((name) => delete self.searchParams[name])
      }
    },
    afterCreate() {
      console.log("Init Search Params")
      self.searchParams = {}
    },
    mergeBatch: flow(function* mergeBatch(batchUuid, sortParams = {}) {
      const queryParams = {
        page: self.page,
        perPage: self.perPage,
        ...sortParams
      };
      const manager = getEnv(self).batchManager
      const response = yield manager.mergeBatch(batchUuid, queryParams);
    }),
    setSelectedBatch(batch){
      console.log("BatchList setSelectedBatch: ", batch)
      self.selectedBatch = batch
    },
    deleteBatch: flow(function* deleteBatch(batchUuid, sortParams = {}) {
      const queryParams = {
        page: self.page,
        perPage: self.perPage,
        ...sortParams
      };
      const manager = getEnv(self).batchManager
      const response = yield manager.deleteBatch(batchUuid, queryParams);
    }),
  })).views((self) => ({
    batchOptionValues() {
      const batches  = self.batchStore.filteredList() || {};
      return batches || []
    },
  }));
