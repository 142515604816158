import React from "react";
import { observer } from "mobx-react-lite";
import AsyncSelect from "react-select/async";
import Select from "react-select";

const BatchSelect = observer(({ batchSelectStore }) => {
  console.log("BatchSelect: batchSelectStore: ", batchSelectStore?.toJSON());
  return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      defaultValue={batchSelectStore?.selectedBatch ? batchSelectStore.selectedBatch : []}
      options={batchSelectStore?.batchStore.downloadBatchOptionsValue()}
      isSearchable
      onChange={(data)=>batchSelectStore?.setSelectedBatch(data.uuid)}
      getOptionLabel={option => option.fileName}
      getOptionValue={option => option.uuid}
      placeholder="Select"
    />
  );
});

export default BatchSelect;