import React, {useState, useContext} from "react";
import {
  Button, Card,
  CardBody,
  CardHeader, CardFooter,
  CardTitle,
  Form,
  FormGroup, Input, CustomInput,
  Label,
  Nav, NavItem, NavLink,
  TabContent, TabPane,
  Row, Col, InputGroup, InputGroupAddon
} from "reactstrap";
import { observer } from "mobx-react-lite";
import { StyledDropzone } from "../../elements/fileUpload/StyledDropzone";
import TagSelect from "../../elements/tags/TagSelect";
import {useIntl, FormattedMessage } from "react-intl";
import GroupSelect from "../../elements/groups/GroupSelect";
import Select from "react-select";
import PublishDomainSelectView from "../../elements/publishDomain/PublishDomainSelectView";
import {StateContext} from "../../../App";
import QrConfigSelect from "../../elements/qrconfig/QrConfigSelect";
import CodeFilterSelect from "../../elements/filters/CodeFilterSelect";
import BatchSelect from "../../elements/batch/BatchSelect";
import QrDownloadView from "../../qr/qrpreview/qrDownload/QrDownloadView";
/**
 Form Component to Upload Batch
 */
const BatchForm = observer(({ batchFromState }) => {
  const intl = useIntl();
  const stateContext = useContext(StateContext);
  batchFromState.setOnSelectChanges();
  batchFromState?.codeFilterSelectState.setEmails(batchFromState?.codeModels?.filters?.users);
  batchFromState?.codeFilterSelectState.setCodeTypes(batchFromState?.codeModels?.filters?.types);
  batchFromState?.codeFilterSelectState.setStatusTypes(batchFromState?.codeModels?.filters?.status);
  batchFromState?.codeFilterSelectState.setDomains(batchFromState?.codeModels?.filters?.domains);
  batchFromState?.codeFilterSelectState.setBehaviorTypes(batchFromState?.codeModels?.filters?.behaviors);
  const { title, handleSubmit, uploadFiles, handleCancel, downloadType, batchType } = batchFromState;
  // const [showTagDownloadBox, setShowTagDownloadBox] = useState(false);

  // const [activeTab, setActiveTab] = useState('files');
  // const toggleTab = (tabId) => {
  //   setActiveTab(tabId);
  //   batchFromState.setDownloadType(tabId);
  // }

  // const [buttonText, setButtonText] = useState('Upload');
  // const handleBatchTypeChange = (event) => {
  //   const selectedValue = event.target.value;
  //   batchFromState.setBatchType(selectedValue);
  //   if (selectedValue === 'Download') {
  //     setShowTagDownloadBox(true);
  //     setButtonText('Download');
  //   } else {
  //     setShowTagDownloadBox(false);
  //     setButtonText('Upload');
  //   }
  // };
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">{title}</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Batch Name:</Label>
            <Input type="text"
                   name="batchName"
                   onChange={batchFromState.handleBatchNameChange}
                   value={batchFromState.batchName}/>
          </FormGroup>
          <FormGroup>
            <Label>
              Batch Type:
            </Label>
            {/* <Input
              type="select"
              id="batchType"
              name="batchType"
              onChange={handleBatchTypeChange}
            >
              <option>Create</option>
              <option>Download</option>
            </Input> */}
            <Select
              id="batchType"
              name="batchType"
              onChange={(data)=>batchFromState.setBatchType(data.value)}
              options={[
                { value: 'Create', label: 'Create' },
                { value: 'Download', label: 'Download' }
              ]}
              defaultValue={{ value: batchType, label: batchType }}
            />
          </FormGroup>
          <hr/>
          {batchType === 'Download' && (
            <>
              <Row>
                <Col sm={5}>
                <Label className="text-sm-left"><FormattedMessage id="batchForm.downloadSwitch.defaultMessage"/></Label>
                </Col>
                <Col sm={3}>
                  <CustomInput
                    type="switch"
                    id="isDefaultBatchDownload"
                    name="isDefaultBatchDownload"
                    checked={batchFromState?.isDefaultBatchDownload}
                    onChange={() => batchFromState.toggleIsDefaultBatchDownload()}
                  />
                </Col>
              </Row>
            </>)
          }
          {!batchFromState?.isDefaultBatchDownload || batchType === 'Create' ? (
              <>
                <FormGroup>
                  <Label className="text-sm-right"><FormattedMessage id="createQr.groups.defaultMessage"/>:</Label>
                  <GroupSelect groupSelectStore={batchFromState.groupSelectStore}/>
                </FormGroup>
                {batchFromState?.dimensionSelectViewState?.dimensionsEnabled && batchFromState?.dimensionSelectViewState?.dimensionSelectStates?.map(dimensionSelectStore => batchFromState.buildDimensions(dimensionSelectStore))}
              </>
            ) : (<></>)
          }

          {batchType === 'Create' && (
            <>
              <FormGroup>
                <Label>
                  Code Experience:
                </Label>
                <Select
                  id="codeExperience"
                  name="codeExperience"
                  onChange={(data)=>batchFromState.setCodeType(data.value)}
                  options={[
                    { value: 'url', label: 'URL' },
                  ]}
                  defaultValue={{ value: 'url', label: 'URL' }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Domain:
                </Label>
                <PublishDomainSelectView publishDomainSelectState={batchFromState.publishDomainSelectStore} configs={ stateContext?.appConfigs?.configs }/>
              </FormGroup>
              <FormGroup>
                <Label>
                  Tags:
                </Label>
                <TagSelect tagSelectStore={batchFromState.tagSelectStore}/>
              </FormGroup>
              <FormGroup>
                <Label>
                  Code Status:
                </Label>
                <Select
                  id="codeStatus"
                  name="codeStatus"
                  onChange={(data)=>batchFromState.setCodeStatus(data.value)}
                  options={[
                    { value: 'DRAFT', label: 'DRAFT' },
                    { value: 'PUBLISHED', label: 'PUBLISHED' }
                  ]}
                  defaultValue={{ value: 'PUBLISHED', label: 'PUBLISHED' }}
                />
              </FormGroup>
            </>
          )}
          {batchType === 'Download' && (
            <>
              {!batchFromState?.isDefaultBatchDownload && (
                  <>
                    <FormGroup>
                      <Label>
                        Tags:
                      </Label>
                      <TagSelect tagSelectStore={batchFromState.tagSelectStore}/>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Filter:
                      </Label>
                      <InputGroup className="mb-3">
                        {/* <InputGroupAddon addonType="prepend">
                          {intl.formatMessage({id: "listCodes.filter.filterName.defaultMessage"})}
                        </InputGroupAddon> */}
                        <CodeFilterSelect codeFilterSelectState={batchFromState.codeFilterSelectState}/>
                      </InputGroup>
                    </FormGroup>
                  </>)}



             {batchFromState?.isDefaultBatchDownload && (
              <>
                <FormGroup>
                  <Label>
                    Batch Name:
                  </Label>
                  <InputGroup className="mb-3">
                    <BatchSelect batchSelectStore={batchFromState.batchSelectStore}/>
                  </InputGroup>
                </FormGroup>
              </>)
              }
              <FormGroup>
                    <Label>
                      Designer Template:
                    </Label>
                    <InputGroup className="mb-3">
                      <QrConfigSelect store={batchFromState.qrConfigSelectStore} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Image Format:
                    </Label>
                    {/* <Select
                      id="imageFormat"
                      name="imageFormat"
                      onChange={(data)=>batchFromState.setImageFormat(data.value)}
                      options={[
                        { label: "PNG", value: "png" },
                        { label: "JPG", value: "jpg" },
                        { label: "BMP", value: "bmp" },
                        { label: "EPS", value: "eps" },
                        { label: "GIF", value: "gif" },
                        { label: "PDF", value: "pdf" },
                        { label: "SVG", value: "svg" }
                      ]}
                      defaultValue={{ label: "PNG", value: "png" }}
                    /> */}
                <QrDownloadView store={batchFromState} />
              </FormGroup>
              {!batchFromState?.isDefaultBatchDownload && (
                  <>
                    <FormGroup>
                      <Label>
                        Total Codes:
                      </Label>
                      {batchFromState.downloadCounts}
                    </FormGroup>
                  </>
                )
              }
            </>
          )}
          {!batchFromState.showTagDownloadBox && (
          <div className="d-flex justify-content-between align-items-center">
            <Nav tabs>
              <NavItem>
                <NavLink className={batchFromState.activeTab === 'files' ? 'active' : ''}
                        onClick={() => batchFromState.setActiveTab('files')}>
                  Select CSV File :
                </NavLink>
              </NavItem>
            </Nav>
            <div><a href="https://cdn.scanbuy.com/apps/cmp-v2/files/batch_create_example_file.csv" download>Download template</a></div>
          </div>
          )}
          <TabContent activeTab={batchFromState.activeTab}>
            {!batchFromState.showTagDownloadBox && (
            <TabPane tabId="files">
              <FormGroup>
                <StyledDropzone id="dropzone" name="dropzone" setUploadFiles={uploadFiles} />
              </FormGroup>
            </TabPane>)}
            {/* tag tab */}
            {/* {batchFromState.showTagDownloadBox && (
            <TabPane tabId="tags">
              <FormGroup>
                <Row className="p-1">
                  <Col>
                    <div className="custom-controls-stacked">
                      <TagSelect tagSelectStore={batchFromState.tagSelectStore}/>
                    </div>
                  </Col>
                </Row>
              </FormGroup>
            </TabPane>)} */}
          </TabContent>
          {/*<FormGroup>*/}
          {/*  <Label>*/}
          {/*    Select File :*/}
          {/*  </Label>*/}
          {/*  <Label>*/}
          {/*    Enter Tags:*/}
          {/*  </Label>*/}
          {/*  <StyledDropzone id="dropzone" name="dropzone" setUploadFiles={uploadFiles} />*/}
          {/*  {showTagDownloadBox && (*/}
          {/*    <FormGroup>*/}
          {/*      <Input type="text" name="tags" />*/}
          {/*    </FormGroup>*/}
          {/*  )}*/}
          {/*</FormGroup>*/}
          <FormGroup>
            <Button color="danger" onClick={handleCancel}>Cancel</Button>
            <Button color="primary" className="float-right">{batchFromState.buttonText}</Button>
          </FormGroup>
        </Form>
      </CardBody>
      {/* <CardFooter> <FormattedMessage id="batchForm.alertInfo.defaultMessage"/></CardFooter> */}
      {batchFromState.formHasErrors && <div className="alert alert" style={{
          fontSize: "20px",
          backgroundColor: "#faada7",
          width: "80%",
          marginLeft: "10%",
          padding:"5px",
          paddingLeft:"8px",
          paddingRight:"8px"
        }} role="alert">
          <div>{batchFromState.errorMessage}</div>
        </div>}
    </Card>
  );
});


export default BatchForm;
