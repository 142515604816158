// noinspection DuplicatedCode

import React, {useContext, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  DropdownItem,
  Row,
} from 'reactstrap';

import * as Icon from "react-feather";
import { observer } from "mobx-react-lite";
import {types} from 'mobx-state-tree';
import { RouterLink } from "mobx-state-router";
import { FormattedMessage } from "react-intl";

import BatchListView from "../../../components/codes/batches/BatchListView";
import { StateContext } from "../../../App";
import { TableColumn } from "../../../components/tables/ScanbuyTable";
import Header from "../../../components/Header";
import TableActionsDropdown from "../../../components/tables/TableActionsDropdown";
import ScanbuyModal, {ModalState} from '../../../components/elements/modal/GenericModal';
import BatchItemListView from '../../../components/codes/batches/items/BatchItemListView';
import {BatchItemList} from '../../../models/batch/BatchItemList';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import BatchItemListState from '../../../components/codes/batches/items/BatchItemListState';
import BatchForm from '../../../components/codes/batches/BatchForm';

export const buildColumns = (routerStore, batchList) => {
  const {rootStore} = routerStore.options;
  const c1 = TableColumn.create({ name: 'uuid', property: 'uuid' });
  c1.setSelector((row) => row.uuid);

  const c2 = TableColumn.create({
    name: 'Status',
    property: 'status',
    sortable: true,
    sortField: 'status',
    selector: 'status',
  })
  c2.setSelector((row)=>{
    let status = row.status;
    // if status is DONE or DONE_WITH_ERRORS, check update time, if it's more than s3 presignUrl TTL time, show it expired
    status = checkStatus(status, row.updatedAt, row.batchType);
    if (status === 'DONE_WITH_ERRORS') {
        return (<><div><span>DONE</span><FontAwesomeIcon style={{color: 'grey'}} icon={faExclamationTriangle}/></div></>)
    } else {
        return (<div>{status}</div>)
    }
  })

  const c3 = TableColumn.create({ name: 'BatchType', property: 'batchType' });
  c3.setSelector((row) => {
    return row.batchType;
  });

  const c4 = TableColumn.create({
    name: 'Batch Name',
    property: 'fileName'
  });
  c4.setSelector((row) => {
    return row.fileName;
  });

  const c5 = TableColumn.create({
    name: 'Total Items',
    property: 'totalItems',
    width: '10%',
    hide: 'sm'
  });
  c5.setSelector((row) => {
    return row.totalItems;
  });

  const c6 = TableColumn.create({
    name: 'Created',
    property: 'created',
    width: '10%',
    hide: 'sm'
  });

  c6.setSelector((row) => {
    return row.createdAt;
  });

  const c7 = TableColumn.create({
    name: 'Updated',
    property: 'updated',
    width: '10%',
    hide: 'sm'
  });

  c7.setSelector((row) => {
    return row.updatedAt;
  });

  const actionColl = TableColumn.create({
    name: 'Actions', property: 'actions', button: true, allowOverflow: true, ignoreRowClick: true
  });

  actionColl.setCell((row) => {
    const status = checkStatus(row.status, row.updatedAt, row.batchType);
    return (
      <div>

          <TableActionsDropdown actionsList={[
            <DropdownItem key={'batches/' + row.uuid + '/items'} onClick={() => {
              routerStore.goTo('batchItems', {
                params: { batchUuid: row.uuid },
              });
            }}><Icon.Archive/>&nbsp;Details</DropdownItem>,
            status === 'DONE' && row.batchType === 'DOWNLOAD' ? (
            <DropdownItem key={'download/' + row.uuid} onClick={() => {
              const downloadFile = async () => {
                // Replace 'url-to-file' with the actual URL of the file to download
                if (row.details.presignUrl && row.details.presignUrl !== "") {
                  saveAs(row.details.presignUrl, row.fileName + '.zip');
                }
              };
              downloadFile();
            }}>
              <Icon.Download/>&nbsp;Download
            </DropdownItem>) : null,
            status === 'MERGEABLE' ? (
              <DropdownItem key={'merge/' + row.uuid} onClick={() => {
                const mergeBatch = async () => {
                  await batchList.mergeBatch(row.uuid)
                }
                mergeBatch();
                batchList.fetchAll()
              }}>
              <Icon.Edit/>&nbsp;Merge
              </DropdownItem>) : null,
            <DropdownItem key={'batches/' + row.uuid} onClick={() => {
              const deleteBatch = async() => {await batchList.deleteBatch(row.uuid)}
              deleteBatch();
              batchList.fetchAll()
            }}><Icon.Delete/>&nbsp;Delete</DropdownItem>
          ]}/>

      </div>
  );
});

  return [
    // c1,
    c4,
    c3,
    c2,
    c5,
    c6,
    c7,
    actionColl
  ];

}

const checkStatus = (status, updatedAt, batchType) => {
  const updateTime = new Date(updatedAt);
  if ((status === 'DONE' || status === 'DONE_WITH_ERRORS') && batchType === 'DOWNLOAD') {
    if (Date.now() - updateTime.getTime() > 7200000) {
      return 'EXPIRED';
    }
  }
  return status;
}

const BatchListPage = observer(() => {
  console.log("Start Page, Batches")
  const stateContext = useContext(StateContext);
  const batchListViewState = stateContext.rootUiStore.batchListViewState
  const history = useHistory();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [tagDownload, setTagDownload] = useState(false);
  // const batchManager = new BulkManager(new HttpClient(config.API_DOMAIN_NAME));

  /* Batch Item Modal*/
  const modalTitle = 'Batch Items';
  const startOpen = false;
  const modalStore = ModalState.create({ open: startOpen, title: modalTitle });
  const batchItemStore = BatchItemList.create();

  const handleViewActionClick = async (rowUuid) => {
    await batchItemStore.updateBatchUuid(rowUuid);
    modalStore.toggle();
  };

  const ComponentStoreState = types
  .model({})
  .actions((self) => ({
    saveAndClose(modalStore) {
      //do save.
      //if ok..
      return modalStore.toggle();
    },
  }));

  const componentStore = ComponentStoreState.create({});

  const batchItemColumn1 = TableColumn.create({ name: 'uuid', property: 'uuid' });
  batchItemColumn1.setSelector((row) => row.uuid);

  const batchItemColumn2 = TableColumn.create({ name: 'batchUuid', property: 'batchUuid' });
  batchItemColumn2.setSelector((row) => row.batchUuid);

  const batchItemColumn3 = TableColumn.create({
    name: 'Status',
    property: 'status',
    sortable: true,
    sortField: 'status',
    selector: 'status',
  })
  batchItemColumn3.setSelector((row)=>{
    return row.status
  })

  const batchItemColumns = [
    batchItemColumn1,
    batchItemColumn2,
    batchItemColumn3
  ];

  const batchItemsListState = BatchItemListState.create({
    store: batchItemStore,
    columns: batchItemColumns,
  });

  const x = () => {
    return (
      <BatchItemListView batchItemsListState={batchItemsListState}/>
    );
  };
  /* Batch Item Modal */

  const actionCol = TableColumn.create({
    name: 'Actions',
    property: 'action',
    allowOverflow: true,
    button: true,
    width: '100px',
  })
  actionCol.setCell((row) => (
    <TableActionsDropdown actionsList={[
      <DropdownItem key={'view/'+row.uuid} onClick={() => handleViewActionClick(row.uuid)}><Icon.Search />View</DropdownItem>,
      <DropdownItem key={'edit/'+row.uuid}><Icon.Edit /><Link to={"/codes/batches/" + row.uuid}>Edit</Link></DropdownItem>,
      <DropdownItem key="divider" divider />,
      <DropdownItem key={'delete/'+row.uuid} className="align-text-bottom"><Icon.Delete /><Link to={"/codes/batches/delete/" + row.uuid}>Delete</Link></DropdownItem>
    ]} />
  ))

  const handleSubmit = async (e) => {
    e.preventDefault();
    let dimensions = {};
    batchFormState.dimensionSelectViewState.dimensionSelectStates.forEach(item => {
      dimensions[item.selectStateUuid] = item.selectedDimension.uuid;
    });
    if (batchFormState.batchType === 'Download'){
      let qrConfig = "";
      let details = {};
      if (batchFormState.qrConfigSelectStore.selectedConfig != undefined) {
        qrConfig = batchFormState.qrConfigSelectStore.selectedConfig.uuid;
      }
      if(batchFormState.isDefaultBatchDownload){
        let defaultDownload = batchFormState.batchSelectStore.selectedBatch.uuid;
        details.defaultDownload = defaultDownload;
      } else {
        details = {
          tags: batchFormState.tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}),
          owner: {
            dimensions: dimensions,
            groupUuid: batchFormState.groupSelectStore.selectedGroup.uuid
          },
          filter: batchFormState.codeModels.searchParams,
        }
      }
      details.imageFormat = batchFormState.qrDownloadStore.downloadImageFormat.value;
      details.qrConfig = qrConfig;
      details.defaultDownload = {batchUuid: batchFormState.batchSelectStore.selectedBatch};

      await batchFormState.handleBulkDownload({
        batchName: batchFormState.batchName,
        details: details
      });
    } else {
      const details = {
        codeType: batchFormState.selectedCodeType,
        codeStatus: batchFormState.codeStatus,
        owner: {
          dimensions: dimensions,
          groupUuid: batchFormState.groupSelectStore.selectedGroup.uuid
        },
        tags: batchFormState.tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}),
        shortUrl: {
          domain: batchFormState.publishDomainSelectStore.selectedPublishDomain?.domain
        }
      }
      await batchFormState.handleCsvUpload({
        batchType: e.target.batchType.value,
        uploadFiles: uploadFiles,
        batchName: batchFormState.batchName,
        details: details
      });
    }
    batchListViewState.refresh()
    history.push("/batches");
  };

  // const batchFormState = BatchFormState.create({title: "Create Batch"})

  const batchFormState = batchListViewState.batchFormState
  batchFormState.setHandleSubmit(handleSubmit)
  batchFormState.setUploadFiles(setUploadFiles)
  ///handleSubmit={handleSubmit} setUploadFiles={setUploadFiles}
  return (
    <Container fluid>
      <Header>
        <Breadcrumb>
          <BreadcrumbItem>
            <RouterLink routeName="dashboard" ><FormattedMessage id="routes.home.defaultMessage"/></RouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <RouterLink routeName="codes" ><FormattedMessage id="routes.codes.defaultMessage"/></RouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem active><FormattedMessage id="routes.batches.defaultMessage"/></BreadcrumbItem>
        </Breadcrumb>
        <ScanbuyModal
          modalStore={modalStore}
          EmbedComponent={x} />
      </Header>
      <Row>
        <Col sm={8}>
          <BatchListView batchListState={batchListViewState} />
        </Col>
        <Col sm={4}>
          <BatchForm batchFromState={batchFormState}  />
        </Col>
      </Row>
    </Container>
  )}
)
export default BatchListPage;
