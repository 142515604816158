import { flow, getEnv, types } from "mobx-state-tree";
import { CodeModel } from "./CodeModel";
import Notification from "../../utils/Notification";

export const CodeModelList = types.model({
  items: types.optional(types.array(CodeModel), []),
  isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
  page: types.optional(types.integer, 1),
  perPage: types.optional(types.integer, 10),
  totalRows: types.optional(types.integer, 0),
  sortOrder: types.optional(types.string, "desc"),
  sortField:  types.optional(types.string, "updatedAt"),
}).volatile(self => ({
  searchParams: {},
  filters: {}
})).actions((self) => ({
  getItems() {
    return self.items
  },
  setSortOrder(sortDirection){
    self.sortOrder = sortDirection
  },
  setSortField(sortField){
    self.sortField = sortField
  },
  fetchAll: flow(function* fetchAll(sortParams = {}) {
    // self.items = []
    self.isLoading = true
    try {
      const queryParams = {
        page: self.page,
        perPage: self.perPage,
        ...sortParams,
        ...self.searchParams
      }

      const manager = getEnv(self).codeManager
      console.log("queryParams in fetchAll",queryParams)
      const response = yield manager.fetchAllCodes(queryParams);

      if(response != null) {
        console.log("response.results", response.results);
        //CodeModel.create({uuid: one.uuid, status: one.status}) //TODO: convert
        response.results.map( (code) => {
          if(code.tags) {
            code.tags.map((tag) => {
              tag.namespace = "codelist"
              tag.uuid = "codelist." + tag.uuid;
            })
          } else {
            code.tags = [];
          }
        })
        self.items = response.results;
        self.totalRows = response._meta.itemCount;
        self.isLoading = false;
      } else {
        self.isLoading = false;
      }
    } catch (error) {
      console.error('Failed to fetch code', error)
    }
  }),
  fetchAllFilters: flow(function* fetchAllFilters(sortParams = {}) {
    self.isLoading = true
    try {
      const queryParams = {
        ...self.searchParams
      }
      
      const manager = getEnv(self).codeManager;
      console.log("codeManager", manager)
      const response = yield manager.fetchAllFilters(queryParams);
      if(response != null) {
        self.filters = response
        self.isLoading = false;
        //CodeModel.create({uuid: one.uuid, status: one.status}) //TODO: convert
      } else {
        self.isLoading = false;
      }
    }
    catch (error) {
      console.error('Failed to fetch code', error)
    }
  }),
  addItems(item){
    self.items.push(item)
  },
  updatePage: function (page) {
    self.page = page
  },
  updatePerPage: function (perPage) {
    self.perPage = perPage
  },
  addSearchParams(param){
    self.searchParams = {...self.searchParams, ...param}
  },
  removeSearchParams(names,regex = ''){
    if(names){
      names.map((name) => delete self.searchParams[name])
    }
    if(regex){
      const keysToDelete = Object.keys(self.searchParams).filter(key => regex.test(key));
      keysToDelete.forEach(key => delete self.searchParams[key]);
    }
  },
  removeItem(uuid){
    self.items = self.items.filter(item => item.uuid !== uuid)
  },
  afterCreate() {
    console.log("Init Search Params")
    self.searchParams = {}
  },
  removeList(){
    self.items = []
  },
  publish: flow(function* (obj) {
    const code = self.items.find(item => item.uuid === obj.uuid)
    const manager = getEnv(self).codeManager
    const resp = yield manager.publish(obj, code);

    obj.formHasErrors && new Notification()
      .setType("error")
      .setMessage(`${code?.name} Code failed publishing`)
      .send();

    !obj.formHasErrors && new Notification()
      .setType("success")
      .setMessage(`${code?.name} Code published`)
      .send();

    !obj.formHasErrors && obj.notifyThirdCallBack(resp.uuid);
    !obj.formHasErrors && obj.notifyCallBack()


  })
})).views((self) => ({
  getSearchParams() {
    return self.searchParams;
  }
}));
