import HttpClient from "../../services/HttpClient";

class BatchManager {
	constructor(httpClient = null) {
		this.httpClient = httpClient ||  new HttpClient();
		this.url = '/batches'
	}

	fetchBatches = async (queryParams) => {
		try {
			const res = await this.httpClient.get(this.url, queryParams);
			if (res) {
				return res;
			}
		} catch (e) {
			console.log("Failed to get batches", e)
		}
		return null;
	}

	fetchBatchItems = async (batchUuid, queryParams) => {
		try {
			const res = await this.httpClient.get(this.url + "/" + batchUuid + "/items", queryParams);
			if (res) {
				return res;
			}
		} catch (e) {
			console.log("Failed to get batch items", e)
		}
		return null;
	}

	mergeBatch = async (batchUuid, queryParams) => {
		try {
			const res = await this.httpClient.post(this.url + "/" + batchUuid + "/actions/merge");
			if (res) {
				return res;
			}
		} catch (e) {
			console.log("Failed to merge batch", e)
		}
		return null;
	}

	deleteBatch = async (batchUuid, queryParams) => {
		try {
			const res = await this.httpClient.destroy(this.url + "/" + batchUuid);
			if (res) {
				return res;
			}
		} catch (e) {
			console.log("Failed to delete batch", e)
		}
		return null;
	}
}

export default BatchManager;
