import { flow, getEnv, types } from "mobx-state-tree";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import Group from "../../../models/Group";
import lodash from "lodash";
import Notification from "../../../utils/Notification";
import { batch } from "react-redux";
import {DimensionSelectViewState} from "../../elements/dimensions/dimensionSelectViewState";
import {
  Label,
  Row,
  FormGroup
} from "reactstrap";
import DimensionSelect from "../../elements/dimensions/dimensionSelect";
import {FormattedMessage, useIntl} from "react-intl";
import {PublishDomainSelectState} from "../../elements/publishDomain/PublishDomainSelectState";
import {CodeFilterSelectState} from "../../elements/filters/CodeFilterSelectState";
import {CodeModelList} from "../../../models/codes/Code";
import {QrConfigSelectState} from "../../elements/qrconfig/QrConfigSelectState";
import { BatchList } from "../../../models/batch/BatchList";
import {QrDownloadState} from "../../qr/qrpreview/qrDownload/QrDownloadState";

const BatchFormState = types.model('BatchFromState',{
  title: types.string,
  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  selectedTags: types.optional(types.array(types.string), []),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, "Null"),
  batchName: types.optional(types.string, ""),
  batchType: types.optional(types.string, "Create"),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),
  selectedGroup: types.maybe(types.reference(Group)),
  dimensionSelectViewState: types.maybe(types.late(() => types.reference(DimensionSelectViewState))),
  selectedDimensions: types.optional(types.string, '{}'),
  showTagDownloadBox: types.optional(types.boolean, false),
  buttonText: types.optional(types.string, "Process"),
  codeStatus: types.optional(types.string, "PUBLISHED"),
  publishDomainSelectStore: types.maybe(types.late(() => types.reference(PublishDomainSelectState))),
  selectedDefaultUrl: types.optional(types.string, ''),
  activeTab: types.optional(types.string, 'files'),
  selectedCodeType: types.optional(types.string, 'url'),
  imageFormat: types.optional(types.string, 'png'),
  codeFilterSelectState: types.maybeNull(types.reference(CodeFilterSelectState)),
  qrConfigSelectStore: types.maybeNull(types.late(() => types.reference(QrConfigSelectState))),
  codeModels: types.late(() => CodeModelList),
  selectedQrConfig: types.optional(types.string, ""),
  downloadCounts: types.optional(types.number, 0),
  isDefaultBatchDownload: types.optional(types.boolean, true),
  batchSelectStore: types.maybe(types.late(() => types.reference(BatchList))),
  qrDownloadStore: types.maybeNull(QrDownloadState)
}).volatile((self)=>({
  handleSubmit: types.fn, //this type doesnt really exist
  handleCancel: types.function,
  // batchManager: types.other,
  uploadFiles: types.fn,
  downloadType: types.string,
  searchParams: {}
}))
  .actions((self)=>({
    setUploadFiles(fn){
      self.uploadFiles = fn;
      // self.files = files;
    },
    setHandleSubmit(fn){
      self.handleSubmit = fn
    },
    handleHasErrors(boolean) {
      self.formHasErrors = boolean
    },
    handleErrorMessage(message) {
      self.errorMessage = message
    },
    setHandleCancel(fn){
      // self.handleCancel = fn
    },
    setOnSelectChanges() {
      self.groupSelectStore?.setConsumer(self.selectGroup)
      self.tagSelectStore?.setConsumer(self.selectTag)
      self.publishDomainSelectStore?.setConsumer(self.selectPublishDomain)
      self.dimensionSelectViewState?.setConsumer(self.selectDimension)
      self.codeFilterSelectState?.setConsumer(self.selectFilter)
      self.qrConfigSelectStore?.setConsumer(self.selectQrConfig)
    },
    addSearchParams(param){
      self.searchParams = {...self.searchParams, ...param}
    },
    removeSearchParams(names){
      if(names){
        names.map((name) => delete self.searchParams[name])
      }
    },
    selectTag(arr) {
      self.selectedTags = arr.flatMap((tag)=> tag.uuid)
      if (self.batchType === 'Download') {
        self.countDownloadQrCodes()
      }
    },
    selectGroup(obj) {
      self.selectedGroup = obj;
      self.addSearchParams({groupUuid: obj?.uuid})
      if (self.batchType === 'Download') {
        self.countDownloadQrCodes()
      }
    },
    selectPublishDomain(obj) {
      self.selectedDefaultUrl = obj.name
      if (self.batchType === 'Download') {
        self.countDownloadQrCodes()
      }
    },
    selectDimension(obj, parentUuid) {
      let existingDimension = JSON.parse(self?.selectedDimensions)
      existingDimension[parentUuid] = obj?.uuid
      existingDimension = lodash.pickBy(existingDimension, value => !(lodash.isEmpty(value))); // to delete empty  object
      self.selectedDimensions = JSON.stringify(existingDimension)
      if (self.batchType === 'Download') {
        self.countDownloadQrCodes()
      }
    },
    selectQrConfig(obj) {
      self.selectedQrConfig = obj.uuid
      const qrConfig = JSON.parse(self.qrConfigSelectStore.selectedConfig.qrConfig)
      self.qrDownloadStore.handleOptions(qrConfig)
    },
    getDimensions() {
      if (lodash.isEmpty(JSON.parse(self.selectedDimensions))) return undefined
      return JSON.parse(self.selectedDimensions)
    },
    setDownloadType(type){
      self.downloadType = type
    },
    handleBulkDownload: flow(function* f(data){
      const manager = getEnv(self).bulkManager;
      const queryParams = {
        ...self.searchParams
      }
      const config = {
        params: queryParams
      }
      const res = yield manager.handleBulkDownload(data, self, config);
      self.formHasErrors && new Notification()
        .setType("error")
        .setMessage(`${self.title} failed updating`)
        .send();
      !self.formHasErrors && new Notification()
        .setType("success")
        .setMessage(`${self.title} updated`)
        .send();
      return res;
    }),
    handleCsvUpload: flow(function* f(data){
      const manager = getEnv(self).bulkManager;
      const res = yield manager.handleCsvUpload(data, self);
      self.formHasErrors && new Notification()
        .setType("error")
        .setMessage(`${self.title} failed updating`)
        .send();
      !self.formHasErrors && new Notification()
        .setType("success")
        .setMessage(`${self.title} updated`)
        .send();
      return res;
    }),
    handleBatchNameChange(e){
      self.batchName = e.target.value
    },
    afterCreate(){
      // self.batchManager = new BulkManager()
    },
    buildDimensions(dimensionSelectStore){
      return (
        <FormGroup>
          <Label className="text-sm-right"><FormattedMessage
            id={`createQr.dimensions.${dimensionSelectStore?.dimension?.label}.defaultMessage`}
            defaultMessage={dimensionSelectStore?.dimension?.label}/>:</Label>
          
            <DimensionSelect dimensionSelectStore={dimensionSelectStore}/>
        </FormGroup>
        
      )
    },
    setBatchType(type){
      self.batchType = type
      if (type === 'Download') {
        self.setShowTagDownloadBox(true)
        self.setActiveTab('tags')
        self.countDownloadQrCodes()
      } else {
        self.setShowTagDownloadBox(false)
        self.setActiveTab('files')
      }
    },
    setShowTagDownloadBox(boolean){
      self.showTagDownloadBox = boolean
    },
    setButtonText(text){
      self.buttonText = text
    },
    setCodeStatus(status){
      self.codeStatus = status
    },
    setActiveTab(tab){
      self.activeTab = tab
      self.setDownloadType(tab)
    },
    setCodeType(experience){
      self.selectedCodeType = experience
    },
    handleImgFormat(format){
      self.imageFormat = format.value
      self.qrDownloadStore.handleDownloadImageFormat(format)
    },
    selectFilter(obj, covered){
      self.codeModels.removeSearchParams(covered,/^dimension/);
      if(obj){
        const transpiledDimensions = {};
        if(!lodash.isEmpty(obj.dimensions)){
          obj.dimensions.forEach(dimension => {
            const [uuid, value] = dimension.split('_');
            if (transpiledDimensions.hasOwnProperty(`dimension[${uuid}]`)) {
              transpiledDimensions[`dimension[${uuid}]`] += ',' + value;
            } else {
              transpiledDimensions[`dimension[${uuid}]`] = value;
            }
          });
          delete obj.dimensions
        }

        const params = lodash.mapValues(obj,(value, key) => {
          return {}[key] = value.join(',');
        });
        self.codeModels.addSearchParams({...params,...transpiledDimensions})
        
        const prevGroupUuid = self.prevGroupUuid || [];
        const currentGroupUuid = obj.groupUuid || [];
        if (!lodash.isEqual(prevGroupUuid, currentGroupUuid)) {
          self.fetchFilters();
          self.filterGroup = false;
          self.prevGroupUuid = currentGroupUuid;
        }
        console.log("select filter code models", self.codeModels.searchParams)
        if (self.batchType === 'Download') {
          self.countDownloadQrCodes()
        }
      }
    },
    fetchFilters() {
      self.codeModels.fetchAllFilters();
    },
    countDownloadQrCodes: flow(function* countDownloadQrCodes(sortParams = {}) {
      const info = self.generateDownloadFilterInfo()
      const queryParams = {
        ...sortParams,
        ...info
      }
      const manager = getEnv(self).bulkManager;
      const count = yield manager.countBatchQrcodes(queryParams);
      self.downloadCounts = count.data.totalQrcodes
    }),
    generateDownloadFilterInfo() {
      let dimensions = {};
      self.dimensionSelectViewState.dimensionSelectStates.forEach(item => {
        dimensions[item.selectStateUuid] = item.selectedDimension.uuid;
      });
      const details = {
        details: {
          tags: self.tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}),
          owner: {
            dimensions: dimensions,
            groupUuid: self.groupSelectStore.selectedGroup.uuid
          },
          filter: self.codeModels.searchParams,
        }
        
      }
      return details
    },
    toggleIsDefaultBatchDownload() {
      self.isDefaultBatchDownload = !self.isDefaultBatchDownload
    },
    setColumns(col) {
			self.columns = col
		},
  }))


export default BatchFormState
